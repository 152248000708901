
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  min-height: 100vh;
  font-family: 'Poppins', sans-serif !important;
  background-color:cornsilk  !important;
  background: linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url(https://www.tjagto.gob.mx/wp-content/uploads/2021/04/hundreds-of-colorful-threads-form-a-bright-rainbow-image_t20_pxo1EO-1.jpg) no-repeat center center fixed;

  color: black   !important
}