.noticiaBar
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    text-align: center;
    color: black;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 11px;
}