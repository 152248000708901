footer
{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #969401;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    font-size: 8px;
}