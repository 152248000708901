.abrirPreguntasFrecuentes {
  color: white;
  position: fixed;
  top: 80px;
  left: 30px;
  background-color: #919301;
  padding: 9px;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 576px) {
  .abrirPreguntasFrecuentes {
    color: white;
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    text-align: center;
    text-decoration: none;
    background-color: #919301;
    padding: 9px;
    border-radius: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
  }
}
