@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

body{
  min-height: 100vh;
  font-family: 'Poppins', sans-serif !important;
  background-color:cornsilk  !important;
  background: linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url(https://www.tjagto.gob.mx/wp-content/uploads/2021/04/hundreds-of-colorful-threads-form-a-bright-rainbow-image_t20_pxo1EO-1.jpg) no-repeat center center fixed;

  color: black   !important
}

@media (max-width: 576px) {
  .FormularioIniciarComponent_logoTJA__1zSOk {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}



footer
{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #969401;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    font-size: 8px;
}

.NoticiasComponent_noticiaBar__1euyK
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    text-align: center;
    color: black;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 11px;
}
.PreguntasFrecuentes_abrirPreguntasFrecuentes__3nvfC {
  color: white;
  position: fixed;
  top: 80px;
  left: 30px;
  background-color: #919301;
  padding: 9px;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 576px) {
  .PreguntasFrecuentes_abrirPreguntasFrecuentes__3nvfC {
    color: white;
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    text-align: center;
    text-decoration: none;
    background-color: #919301;
    padding: 9px;
    border-radius: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
  }
}

